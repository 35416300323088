import React from 'react';
import { Helmet } from 'react-helmet'


// import sections
import Hero from '../components/sections/Hero';
import FeaturesSplit from '../components/sections/FeaturesSplit';

const TITLE = 'Say hello to Yuna Yii - Virtual Human & Influencer'

const Home = () => {

  return (
    <>
      <Helmet>
          <title>{ TITLE }</title>
      </Helmet>
      <Hero className="illustration-section-01" />
      <FeaturesSplit invertMobile topDivider imageFill className="illustration-section-02" />
    </>
  );
}

export default Home;