import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet'

import { SectionSplitProps } from './../utils/SectionProps';
// import sections
import Hero from '../components/sections/HeroAbout';
import SectionHeader from './../components/sections/partials/SectionHeader';

const TITLE = 'Learn more about Yuna | Yuna Yii - Virtual Human & Influencer'
const DESCRIPTION = 'Learn more about Yuna Yii. Yuna is 18 and was created with Blender, is rendered with Nvidia Iray and Unreal Engine 5. '

const propTypes = {
    ...SectionSplitProps.types
  }
  
  const defaultProps = {
    ...SectionSplitProps.defaults
  }

  const Profile = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    imageFill,
    ...props
  }) => {

    const outerClasses = classNames(
        'features-split section',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    
      const innerClasses = classNames(
        'features-split-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
      );
    
      const splitClasses = classNames(
        'split-wrap',
        invertMobile && 'invert-mobile',
        invertDesktop && 'invert-desktop',
        alignTop && 'align-top'
      );
    
      const sectionHeader = {
        title: 'About Yuna',
        paragraph: 'Coming Soon'
      };

  return (
    <>
    <Helmet>
        <title>{ TITLE }</title>
        <meta name="description" content={ DESCRIPTION } />
      </Helmet>
      <Hero className="illustration-section-01" />
      <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />

          <div className={splitClasses}>

          </div>
        </div>
      </div>
    </section>
    </>
  );
}
Profile.propTypes = propTypes;
Profile.defaultProps = defaultProps;

export default Profile;